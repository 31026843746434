/* Basic styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  .app-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 80vh; /* Limit the height to 80% of the viewport height */
  }
  
  h1 {
    font-size: 24px;
    color: #333;
  }
  
  p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
  }
  
  label {
    font-size: 14px;
    margin-right: 8px;
  }
  
  select {
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
  }
  
  /* Image styling */
  .story-image {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  /* Mobile responsive styles */
  @media (max-width: 600px) {
    body {
      overflow-x: hidden; /* Prevent horizontal scrolling */
    }
  
    .app-container {
      max-width: 100%;
      padding: 15px;
      max-height: 90vh; /* Allow more height on small screens */
      overflow-y: auto; /* Allow scrolling if content exceeds */
    }
  
    h1 {
      font-size: 20px;
    }
  
    p {
      font-size: 14px;
    }
  
    select {
      width: 100%;
      font-size: 16px;
      padding: 10px;
    }
  }
  