body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
}

header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

canvas {
  border: 2px solid #000;
  background-color: black; /* Black background for the canvas */
  width: 300px;
  height: 400px;
  position: relative;
}

.controls-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.controls-wrapper .row-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.controls-wrapper button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.controls-wrapper button:hover {
  background-color: #0056b3;
}

.reset-button {
  margin-top: 15px;
  padding: 10px 25px;
  font-size: 16px;
  color: #fff;
  background-color: #dc3545;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reset-button:hover {
  background-color: #b02a37;
}
.img-placeholder {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 120px;
  height: 120px;
  border: 2px solid #000;
  background-color: #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #555;
  text-align: center;
}

.img-placeholder video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.header {
  align-self: first baseline;
}
