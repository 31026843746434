body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fae1dd;
}

.container {
  position: relative;
}

.heading {
  font-family: "Brush Script MT", cursive;
  font-size: 50px;
  text-align: center;
  color: #003049;
  margin-bottom: 120px;
}

.valentines {
  position: relative;
  margin-left: 10%;
  margin-right: 20%;
  top: 50px;
  cursor: pointer;
  animation: up 3s linear infinite;
}

@keyframes up {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}

.envelope {
  position: relative;
  width: 300px;
  height: 200px;
  margin-bottom: 200px;
  background-color: #f08080;
}

.envelope:before {
  background-color: #f08080;
  content: "";
  position: absolute;
  width: 212px;
  height: 212px;
  transform: rotate(45deg);
  top: -105px;
  left: 44px;
  border-radius: 30px 0 0 0;
}

.card {
  position: absolute;
  background-color: #eae2b7;
  border-style: none;
  width: 270px;
  height: 170px;
  top: 5px;
  left: 15px;
  box-shadow: -5px -5px 100px rgba(0, 0, 0, 0.4);
}

.card:before {
  content: "";
  position: absolute;
  border: 3px solid #003049;
  border-style: none;
  width: 240px;
  height: 140px;
  left: 12px;
  top: 12px;
}

.wish-button {
  background-color: #ff7eb9; /* Soft pink color */
  color: white; /* Text color */
  border: none; /* Remove border */
  padding: 12px 24px; /* Add padding for a bigger clickable area */
  font-size: 16px; /* Make text more readable */
  font-weight: bold; /* Make text bold */
  border-radius: 25px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

.wish-button:hover {
  background-color: #ff4da6; /* Slightly darker pink when hovered */
  transform: translateY(-2px); /* Subtle lift effect */
}

.wish-button:active {
  background-color: #e60073; /* Even darker color on click */
  transform: translateY(0); /* Reset lift effect */
}

.text {
  position: absolute;
  font-family: "Brush Script MT", cursive;
  font-size: 28px;
  text-align: center;
  line-height: 25px;
  top: 19px;
  left: 85px;
  color: #003049;
}

.heart {
  background-color: #d62828;
  display: inline-block;
  height: 30px;
  margin: 0 10px;
  position: relative;
  top: 110px;
  left: 105px;
  transform: rotate(-45deg);
  width: 30px;
}

.heart:before,
.heart:after {
  content: "";
  background-color: #d62828;
  border-radius: 50%;
  height: 30px;
  position: absolute;
  width: 30px;
}

.heart:before {
  top: -15px;
  left: 0;
}

.heart:after {
  left: 15px;
  top: 0;
}

.hearts {
  position: absolute;
}

.one,
.two,
.three,
.four,
.five {
  background-color: red;
  display: inline-block;
  height: 10px;
  margin: 0 10px;
  position: relative;
  transform: rotate(-45deg);
  width: 10px;
  top: 50px;
}

.one:before,
.one:after,
.two:before,
.two:after,
.three:before,
.three:after,
.four:before,
.four:after,
.five:before,
.five:after {
  content: "";
  background-color: red;
  border-radius: 50%;
  height: 10px;
  position: absolute;
  width: 10px;
}

.one:before,
.two:before,
.three:before,
.four:before,
.five:before {
  top: -5px;
  left: 0;
}

.one:after,
.two:after,
.three:after,
.four:after,
.five:after {
  left: 5px;
  top: 0;
}

.one {
  left: 10px;
  animation: heart 1s ease-out infinite;
}

.two {
  left: 30px;
  animation: heart 2s ease-out infinite;
}

.three {
  left: 50px;
  animation: heart 1.5s ease-out infinite;
}

.four {
  left: 70px;
  animation: heart 2.3s ease-out infinite;
}

.five {
  left: 90px;
  animation: heart 1.7s ease-out infinite;
}

@keyframes heart {
  0% {
    transform: translateY(0) rotate(-45deg) scale(0.3);
    opacity: 1;
  }
  100% {
    transform: translateY(-150px) rotate(-45deg) scale(1.3);
    opacity: 0.5;
  }
}

.front {
  position: absolute;
  border-right: 180px solid #f4978e;
  border-top: 95px solid transparent;
  border-bottom: 100px solid transparent;
  left: 120px;
  top: 5px;
  width: 0;
  height: 0;
  z-index: 10;
}

.front:before {
  position: absolute;
  content: "";
  border-left: 300px solid #f8ad9d;
  border-top: 195px solid transparent;
  left: -120px;
  top: -95px;
  width: 0;
  height: 0;
}

.shadow {
  position: absolute;
  width: 330px;
  height: 25px;
  margin-left: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 265px;
  left: -15px;
  animation: scale 3s linear infinite;
  z-index: -1;
}

@keyframes scale {
  0%,
  100% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.85);
  }
}
