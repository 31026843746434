/* General styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.image-upload-container {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 90%;
  margin: 1rem;
}

h1 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

/* File input styling */
.file-input-container {
  margin-bottom: 1.5rem;
}

.file-input-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.file-input-label:hover {
  background-color: #0056b3;
}

#file-input {
  display: none;
}

/* Image preview styling */
.image-preview {
  margin-top: 1.5rem;
}

.image-preview img {
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Submit button styling */
.submit-button {
  margin-top: 1.5rem;
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  width: 100%;
}

.submit-button:hover {
  background-color: #218838;
}

/* Upload status styling */
.upload-status {
  margin-top: 1rem;
  color: #333;
  font-weight: bold;
  font-size: 0.9rem;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .image-upload-container {
    padding: 1rem;
  }

  h1 {
    font-size: 1.25rem;
  }

  .file-input-label {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .submit-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .upload-status {
    font-size: 0.8rem;
  }
}
