body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
}

.board {
  display: grid;
  grid-template-columns: repeat(20, 20px);
  grid-template-rows: repeat(20, 20px);
}

.row {
  display: contents;
}

.cell {
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
}

.snake {
  background-color: green;
}

.food {
  background-color: red;
}

.game-over {
  margin-top: 20px;
  text-align: center;
}
.joystick-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.joystick-button {
  width: 50px;
  height: 50px;
  font-size: 14px;
  font-weight: bold;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin: 5px;
}

.joystick-row {
  display: flex;
  justify-content: space-between;
}

.reset-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.reset-button:hover {
  background-color: #3e8e41;
}
